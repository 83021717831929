.ui.menu .active.item {
  background: rgba(0, 0, 0, 0.1); }

.ui.menu.mainNav,
.ui.menu.subMenu {
  border-top: 0px;
  display: flex;
  flex-wrap: wrap; }

.ui.menu.mainNav,
.ui.menu.footer {
  background: #6f2296;
  border-radius: 0;
  margin-bottom: 0; }
  .ui.menu.mainNav .logo,
  .ui.menu.footer .logo {
    height: 25px; }
  .ui.menu.mainNav .item,
  .ui.menu.footer .item {
    font-family: "Ubuntu", sans-serif;
    color: white;
    font-size: 1.2rem; }
    .ui.menu.mainNav .item svg,
    .ui.menu.footer .item svg {
      margin-right: 1rem; }
  .ui.menu.mainNav .item:hover,
  .ui.menu.mainNav .dropdown.item:hover,
  .ui.menu.mainNav .link.item:hover,
  .ui.menu.mainNav a.item:hover,
  .ui.menu.footer .item:hover,
  .ui.menu.footer .dropdown.item:hover,
  .ui.menu.footer .link.item:hover,
  .ui.menu.footer a.item:hover {
    color: #bbb8b8; }
  .ui.menu.mainNav .active.item,
  .ui.menu.footer .active.item {
    background: rgba(0, 0, 0, 0.3); }

.ui.menu.subMenu {
  border-radius: 0;
  background: #8543a6;
  margin: 0; }
  .ui.menu.subMenu.case {
    background: #1ea09a; }
  .ui.menu.subMenu .item {
    font-family: "Ubuntu", sans-serif;
    color: white;
    font-size: 1.1rem; }
    .ui.menu.subMenu .item svg {
      margin-right: 1rem; }
    .ui.menu.subMenu .item .ui.header {
      color: #dedede;
      font-weight: 300; }
  .ui.menu.subMenu .item:active,
  .ui.menu.subMenu .link.item:active,
  .ui.menu.subMenu a.item:active,
  .ui.menu.subMenu a.item:hover {
    color: white;
    background: rgba(0, 0, 0, 0.15); }
  .ui.menu.subMenu .active.item {
    background: rgba(0, 0, 0, 0.3); }

.ui.segment.analyticsSection .ui.secondary.menu .active.item {
  box-shadow: none;
  background: #26a39d;
  color: white;
  border-radius: 0.28571429rem; }

.ui.menu .dropdown.item.menuDropdown .menu {
  background: #6f2296;
  border-radius: 0px;
  min-width: 100%; }
  .ui.menu .dropdown.item.menuDropdown .menu .item,
  .ui.menu .dropdown.item.menuDropdown .menu .header {
    color: white !important; }

.ui.menu .dropdown.item.menuDropdownLight {
  color: white !important; }
  .ui.menu .dropdown.item.menuDropdownLight .menu {
    background: #8543a6;
    border-radius: 0px;
    min-width: 100%; }
  .ui.menu .dropdown.item.menuDropdownLight .item,
  .ui.menu .dropdown.item.menuDropdownLight .item:hover,
  .ui.menu .dropdown.item.menuDropdownLight .header {
    color: white !important;
    font-weight: 400 !important; }
  .ui.menu .dropdown.item.menuDropdownLight .active.item {
    background: rgba(0, 0, 0, 0.3) !important;
    color: white !important; }

@media only screen and (max-width: 768px) {
  .pageHolder {
    padding: 0 !important; } }

@media only screen and (max-width: 375px) {
  .pageHolder {
    padding: 0 !important; }
  .ui.segment {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .ui.grid.dataGrid,
  .ui.grid.caseVGrid,
  .ui.grid.detailsGrid,
  .ui.grid.companyGrid {
    padding: 0.4rem !important; } }

.placeholder-ul {
  visibility: hidden; }

.placeholder-li:hover {
  background: #F1F1F1; }

#masqueradeBar {
  background: #db2828;
  color: white;
  text-align: center;
  padding: 10px; }

body {
  background-color: #dedede;
  font-family: "Ubuntu", sans-serif; }

.no-pad {
  padding: 0 !important; }

.no-padR {
  padding-right: 0 !important; }

.no-padL {
  padding-left: 0 !important; }

.no-hpad {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.subtle {
  color: #ccc; }

.no-marg {
  margin: 0 !important; }

.no-hmarg {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-vmarg {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.pointer {
  cursor: pointer; }

.selectable {
  cursor: pointer; }

.pageWrapper {
  position: relative; }

.pageHolder {
  padding-bottom: 74px !important; }

.ui.statistic > .value,
.ui.statistics .statistic > .value {
  font-size: 3rem !important; }

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background: #6f2296 !important;
  color: rgba(255, 255, 255, 0.9); }

.ui.basic.button:hover,
.ui.basic.buttons .button:hover,
.ui.basic.button:active,
.ui.basic.buttons .button:active {
  background: #6f2296 !important; }

.ui.segment {
  border: 0;
  margin-top: 0 !important; }

.ui.segment.border {
  border: 4px solid #8442a6;
  border-radius: 1rem;
  box-shadow: 0 1px 2px 1px #5251537c; }

.ui.segment.border-case {
  border: 4px solid rgba(22, 112, 108, 0.63);
  border-radius: 1rem;
  box-shadow: 0 1px 2px 1px #5251537c; }

.ui.pagination.menu .item {
  min-width: 3em;
  text-align: center; }

h1.ui.header {
  color: #6f2296; }

.ui.header.pageTitle {
  letter-spacing: 0.4rem;
  text-shadow: 0px 1px 1px #ffffff;
  color: rgba(73, 23, 99, 0.54);
  font-size: 2.2rem;
  font-weight: 900;
  font-family: "Ubuntu", sans-serif; }

.ui.header.pageTitle.case {
  color: rgba(22, 112, 108, 0.63); }

.ui.header.pageTitle.main {
  color: rgba(73, 23, 99, 0.75); }

.ui.header.pageSubTitle {
  letter-spacing: 0.2rem;
  text-shadow: 0px 1px 1px #ffffff;
  color: rgba(73, 23, 99, 0.54);
  font-size: 1.5em;
  font-weight: 900;
  font-family: "Ubuntu", sans-serif; }

.compDetails h5.ui.header {
  color: black;
  font-size: 1.2rem; }

.compDetails .ui.header {
  color: #6f2296;
  margin: 0.3rem; }
  .compDetails .ui.header span {
    margin-left: 0.5rem;
    color: #3a3a3a; }

.ui.statistic > .value,
.ui.statistics .statistic > .value {
  color: #6f2296; }

.ui.statistic > .label,
.ui.statistics .statistic > .label {
  color: #1ea09a; }

.addressCol h4.ui.header {
  margin: 0.2rem; }

.ui.grid.companyGrid > .row.columnRow {
  padding: 0; }
  .ui.grid.companyGrid > .row.columnRow .ui.header {
    color: #6f2296;
    margin: 0.2rem; }
  .ui.grid.companyGrid > .row.columnRow p {
    font-size: 1.2rem; }

.ui.grid.detailsGrid {
  border: 4px solid #8442a6;
  border-radius: 1rem;
  box-shadow: 0 1px 2px 1px #5251537c;
  background: white; }
  .ui.grid.detailsGrid.case {
    border: 4px solid #1ea09a; }
  .ui.grid.detailsGrid .row {
    box-shadow: 0 -1px 0 0 #8442a62e;
    padding: 0; }
  .ui.grid.detailsGrid > .row > .column {
    padding: 0.1rem 1rem;
    font-size: 1.1rem; }

.ui.grid.dataGrid {
  border: 4px solid #8442a6;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 1px 2px 1px #5251537c; }
  .ui.grid.dataGrid.case {
    border: 4px solid #1ea09a; }
  .ui.grid.dataGrid .row {
    box-shadow: 0 -1px 0 0 #8442a62e;
    padding: 0; }
  .ui.grid.dataGrid > .row > .column {
    padding: 0.4rem 1rem;
    font-size: 1.1rem;
    justify-content: space-between;
    display: flex; }
    .ui.grid.dataGrid > .row > .column .ui.header {
      font-size: 1.2rem;
      margin: 0;
      padding-right: 0.4rem; }

.ui.grid.caseVGrid {
  border: 4px solid #1ea09a;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 1px 2px 1px #5251537c; }
  .ui.grid.caseVGrid .row {
    box-shadow: 0 -1px 0 0 rgba(30, 160, 153, 0.315);
    padding: 0; }
  .ui.grid.caseVGrid > .row > .column {
    padding: 0.4rem 1rem;
    font-size: 1.1rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column; }
    .ui.grid.caseVGrid > .row > .column .ui.header {
      font-size: 1.2rem;
      margin: 0;
      padding-right: 0.4rem; }
    .ui.grid.caseVGrid > .row > .column h1.ui.header {
      font-size: 3rem; }
    .ui.grid.caseVGrid > .row > .column h2.ui.header {
      font-size: 2rem; }

.modals .ui.form .disabled.field,
.modals .ui.form .disabled.fields .field,
.modals .ui.form .field :disabled,
.modals .ui.form .field.disabled > label,
.modals .ui.form .fields.disabled > label,
.modals .ui.disabled.dropdown,
.modals .ui.dropdown .menu > .disabled.item,
.modals .ui.disabled.input, .modals .ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important;
  border: 0; }

.modals .ui.disabled.dropdown i.dropdown.icon {
  display: none; }

.ui.modal > .header {
  background: #6f2296;
  color: white; }

.ui.modal > .content {
  padding: 0; }

.benefitPage .ui.statistic > .value,
.benefitPage .ui.statistics .statistic > .value {
  font-size: 3rem !important; }

.ui.card.dashCard {
  text-align: center !important;
  border-collapse: separate !important;
  border-radius: 0.4rem !important;
  overflow: hidden;
  overflow-y: auto;
  max-height: 400px;
  width: 100%;
  background: #6f2296; }
  .ui.card.dashCard .content {
    background: #6f2296;
    padding: 0; }
  .ui.card.dashCard .header {
    color: #fff;
    font-size: 1.4rem !important;
    padding: 0.8rem;
    background-color: #6f2296; }
  .ui.card.dashCard .stat {
    color: #fff;
    font-size: 5rem !important;
    background: #6f2296;
    padding: 1.5rem;
    font-weight: 700; }
  .ui.card.dashCard .ui.table {
    border-radius: 0;
    background: #8543a6;
    color: #fff; }
    .ui.card.dashCard .ui.table thead th {
      background: #6f2296;
      color: #fff; }

.ui.card.dashCard:hover {
  background: #6f2296; }

.ui.card.actionCard:hover {
  background: #1ea09a; }

.ui.card.actionCard {
  text-align: center !important;
  border-collapse: separate !important;
  border-radius: 0.4rem !important;
  overflow: hidden;
  width: 100%;
  padding-bottom: 1rem;
  background: #1ea09a; }
  .ui.card.actionCard .content {
    background: #1ea09a;
    padding: 0; }
  .ui.card.actionCard .header {
    color: #fff;
    font-size: 1.4rem !important;
    padding: 0.8rem;
    background-color: #22b5af; }
  .ui.card.actionCard .ui.statistic > .value,
  .ui.card.actionCard .ui.statistic .label {
    color: white !important; }
  .ui.card.actionCard .stat {
    justify-content: space-around;
    display: flex; }
    .ui.card.actionCard .stat .ui.header.statValue {
      font-size: 4rem !important;
      margin: 0 !important;
      padding: 0.5rem !important;
      background: #1ea09a; }
    .ui.card.actionCard .stat .ui.header.statLabel {
      font-size: 1.2rem !important;
      margin: 0;
      padding: 0.2rem !important;
      background: #1ea09a; }
  .ui.card.actionCard .actionCardGrid span {
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 1rem; }

.ui.segment.scrollList {
  overflow-y: scroll;
  min-height: 50%; }

.ui.segment.switchBox {
  flex-direction: column;
  display: flex; }
  .ui.segment.switchBox .ui.toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.messageColourFix .content {
  background: #fff6f6 !important; }

.newReferral {
  background: rgba(144, 238, 144, 0.5); }

.newReferral:hover {
  background: rgba(125, 212, 125, 0.623); }

.lateReferral {
  background: rgba(255, 166, 0, 0.521); }

.lateReferral:hover {
  background: #e69500; }

.overdueReferral {
  background: rgba(255, 0, 0, 0.459); }

.overdueReferral:hover {
  background: #db0000; }

.wrapper.companyHolder .smallPad {
  padding: 0.1rem; }

.wrapper.companyHolder .footer h2 {
  font-size: 1.2rem;
  color: #fff; }

.wrapper.companyHolder .ui.table {
  border-radius: 10px; }
  .wrapper.companyHolder .ui.table .tableHeader {
    background: #fff6f6;
    border-radius: 2px; }

.wrapper.companyHolder .ui.header.sectionTitle {
  letter-spacing: 0.4rem;
  text-shadow: 0px 1px 1px #ffffff;
  color: rgba(73, 23, 99, 0.54);
  font-size: 1.4rem;
  font-weight: 900;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
  margin-bottom: 0.1rem; }

.wrapper.companyHolder .ui.basic.button:hover,
.wrapper.companyHolder .ui.basic.buttons .button:hover,
.wrapper.companyHolder .ui.basic.button:active,
.wrapper.companyHolder .ui.basic.buttons .button:active {
  background: #6f229642 !important;
  color: white !important; }

.wrapper.companyHolder .border {
  border: 4px solid #8442a6;
  border-radius: 1rem;
  box-shadow: 0 1px 2px 1px #5251537c; }

.wrapper.companyHolder .border.case {
  border: 4px solid #1ea09a; }

.inner-container {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .inner-container .sub-header {
    text-align: center;
    font-size: 18px;
    margin-bottom: 7px; }
  .inner-container .draggable-container-hovered {
    border: 2px dashed white !important; }
  .inner-container .draggable-container {
    width: 100%;
    min-height: 4rem;
    max-height: 8em;
    background-color: rgba(147, 148, 148, 0.23);
    padding: 6px;
    border-radius: 4px;
    border: 2px dashed rgba(95, 92, 92, 0.2);
    font-size: 14px;
    display: flex;
    margin-bottom: 7px;
    position: relative;
    overflow: hidden; }
    .inner-container .draggable-container .hovered {
      border: 2px dashed white !important; }
    .inner-container .draggable-container #file-browser-input {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: absolute;
      color: transparent;
      opacity: 0;
      z-index: 10; }
    .inner-container .draggable-container .files-preview-container {
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: row;
      flex-wrap: nowrap;
      z-index: 99;
      overflow-x: auto;
      overflow-y: hidden;
      flex-shrink: 0;
      padding: 5px; }
      .inner-container .draggable-container .files-preview-container .file {
        width: 5em;
        height: 58px;
        background-color: rgba(101, 97, 97, 0.34);
        position: relative;
        margin-left: 8px;
        border: 1px solid #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 3px rgba(15, 15, 15, 0.2);
        flex-shrink: 0; }
        .inner-container .draggable-container .files-preview-container .file img {
          width: 100%;
          height: 100%; }
        .inner-container .draggable-container .files-preview-container .file .container {
          display: flex;
          position: absolute;
          flex-direction: column;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          transition: background, 160ms ease-in-out; }
          .inner-container .draggable-container .files-preview-container .file .container:hover {
            background-color: rgba(154, 151, 151, 0.29); }
            .inner-container .draggable-container .files-preview-container .file .container:hover .remove-btn {
              visibility: visible; }
    .inner-container .draggable-container .helper-text {
      font-size: 18px;
      position: absolute;
      display: flex;
      justify-self: center;
      align-self: center;
      left: 50%;
      transform: translateX(-50%);
      color: #797676;
      text-align: center; }
    .inner-container .draggable-container .file-browser-container {
      position: absolute;
      display: flex;
      justify-self: center;
      align-self: center;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%); }

.preview-image {
  margin-left: auto;
  margin-right: auto;
  max-height: 300px;
  max-width: 300px; }

.ui.selectable.table tbody tr.redItem {
  background: #ffc0cb; }

.ui.selectable.table tbody tr.redItem:hover {
  background: #ffa0b0 !important; }

.draggable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }
